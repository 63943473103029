.spinner {
  animation: spinner 0.75s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}